import axios from "axios";

// services.js
const state = {
  serviceList: [],
  selectedService: null,
  currentItem: null, // this is for the current item in the list
};

const mutations = {
  setServiceList(state, services) {
    state.serviceList = services;
  },
  setSelectedService(state, service) {
    state.selectedService = service;
  },
  TOGGLE_ROTATION(state, index) {
    state.serviceList[index].isRotated = !state.serviceList[index].isRotated;
  },
  SET_CURRENT_ITEM(state, item) {
    state.currentItem = item;
  },
};

const actions = {
  fetchServices({ commit }) {
    // Assuming you have a method to fetch services from a file or API
    const services = require("../json/services.json");
    commit("setServiceList", services);
  },
  selectService({ commit, state }, serviceId) {
    const selectedService = state.serviceList.find(
      (service) => service.id === serviceId
    );
    commit("setSelectedService", selectedService);
  },

  rotateCard({ commit }, index) {
    commit("TOGGLE_ROTATION", index);
  },

  // Find and set the current item by its ID
  fetchAndSetSelectedItem({ commit, state }, { serviceId, itemId }) {
    // Assuming the services data is already in the state
    // Find the service by serviceId
    const service = state.serviceList.find((s) => s.id === serviceId);
    if (service && service.items) {
      // Find the item by itemId within the found service
      const item = service.items.find((item) => item.id === itemId);

      // Commit the mutation to set the selected item
      commit("SET_CURRENT_ITEM", item);
    } else {
      // If no item is found, commit null
      commit("SET_CURRENT_ITEM", null);
    }
  },
};

const getters = {
  getServiceById: (state) => (id) => {
    return state.serviceList.find((service) => service.id === id);
  },
  currentItem: (state) => {
    return state.currentItem;
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
