<template>
  <!--====== FEATURE ONE PART START ======-->
  <section class="features-area features-one">
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-lg-6">
          <div class="section-title text-center">
            <h3 class="title">OUR SERVICES</h3>
            <div class="section-divider mt-3"></div>
            <p class="text">
              Stop wasting time and money designing and managing a website that
              doesn’t get results. Happiness guaranteed!
            </p>
          </div>
          <!-- row -->
        </div>
      </div>

      <div class="wrapper-con d-flex justify-content-center flex-wrap mt-5">
        <div
          class="single-card mx-3 mb-5 col-lg-4 col-md-7 col-11"
          v-for="(item, index) in fetchedServices"
          :key="item"
          :class="{ 'rotate-card': item.isRotated }"
        >
          <div class="front p-3" @click="rotateCard(index)">
            <div class="features-icon">
              <img :src="item.image_url" alt="image" width="160" />
            </div>

            <div>
              <h1 class="card-title">{{ item.title }}</h1>
            </div>
          </div>

          <div class="back p-4" @click="rotateCard(index)">
            <div class="content">
              <div>
                <p class="description">{{ item.short_description }}</p>
              </div>

              <div
                class="read_more_btn btn btn-primary"
                @click="goToServiceDetail(item.id)"
              >
                Read More
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- container -->
  </section>
  <!--====== FEATURE ONE PART ENDS ======-->
</template>

<script>
import { mapState } from "vuex";
import axios from "axios";
export default {
  data() {
    return {
      isCardRotated: false,
      fetchedServices: [],
      services: [
        {
          title: "Outdoor Advertising (OOH)",
          description:
            "Maximize your brand's visibility with our strategic outdoor advertising solutions, tailored to reach your target audience effectively.",
          image: "https://magency.s3.eu-central-1.amazonaws.com/images/1-A.png",
          isRotated: false,
        },
        {
          title: "Video Production and Photography",
          description:
            "Transform your stories into visually stunning narratives with our state-of-the-art video production and professional photography services.",
          image: "https://magency.s3.eu-central-1.amazonaws.com/images/1.png",
          isRotated: false,
        },
        {
          title: "Graphic Design and Motion Graphics",
          description:
            "From compelling graphics to captivating motion designs, our creative team ensures your message stands out.",
          image:
            "https://assets-global.website-files.com/64f6d43a08e977747f955d61/651d40fb0b4ef509c959318c_Creativity.jpg",
          isRotated: false,
        },
        {
          title: "Innovation and AI",
          description:
            "Leveraging AI and innovative technologies, we deliver forward-thinking solutions that keep you ahead in the market.",
          image:
            "https://assets-global.website-files.com/64f6d43a08e977747f955d61/651d3f7443db221870f31b40_AI.jpg",
          isRotated: false,
        },
        {
          title: "Web Design and APP Development",
          description:
            "Crafting user-centric websites and mobile applications that drive engagement and deliver a seamless user experience.",
          image:
            "https://assets-global.website-files.com/64f6d43a08e977747f955d61/651d3f75018a068fd8024a17_webstie.png",
          isRotated: false,
        },
        {
          title: "Social Media Management",
          description:
            "Elevate your social media presence with our strategic management, engaging content, and analytics-driven approach.",
          image:
            "https://assets-global.website-files.com/64f6d43a08e977747f955d61/651d3f758fa93c3fdc2c596a_social-media-icons-spheres.png",
          isRotated: false,
        },
        {
          title: "Content Marketing and Copywriting",
          description:
            "Boost your brand’s voice with our expert content marketing and copywriting services, tailored to engage and convert your audience.",
          image:
            "https://assets-global.website-files.com/64f6d43a08e977747f955d61/651d3f7455ca7d366aa64699_pen-and-paPER.png",
          isRotated: false,
        },
        {
          title: "Printing and Production",
          description:
            "High-quality printing and production services to bring your marketing materials to life with excellence and efficiency.",
          image:
            "https://assets-global.website-files.com/64f6d43a08e977747f955d61/651d3f74b6bfa459a2c8564e_MA_Creativity_Flex_Printing_.png",
          isRotated: false,
        },
        {
          title: "Event Management",
          description:
            "From concept to execution, our team creates memorable events that align perfectly with your brand’s objectives.",
          image:
            "https://assets-global.website-files.com/64f6d43a08e977747f955d61/651d41764f4d0833d42d4b6b_Event.jpg",
          isRotated: false,
        },
        {
          title: "Media Marketing",
          description:
            "Expertise in media marketing strategies that ensure your message reaches the right audience at the right time.",
          image:
            "https://assets-global.website-files.com/64f6d43a08e977747f955d61/651d3f743501be4e9e0a5e3f_MA_Creativity_media_channels.png",
          isRotated: false,
        },
        {
          title: "Influencer Marketing",
          description:
            "Connect with key influencers in your industry to amplify your brand’s message and reach a wider audience.",
          image:
            "https://assets-global.website-files.com/64f6d43a08e977747f955d61/651d3f742a214791acfe223e_hand.png",
          isRotated: false,
        },
      ],
      apiUrl: process.env.VUE_APP_API_URL,
    };
  },

  computed: {
    ...mapState("services", ["serviceList"]),
    filteredItems() {
      return this.services;
    },
  },
  created() {
    this.$store.dispatch("services/fetchServices");
    this.fetchServices();
  },

  beforeMount() {},
  methods: {
    rotateCard(index) {
      // this.$store.dispatch("services/rotateCard", index);

      this.fetchedServices[index].isRotated =
        !this.fetchedServices[index].isRotated;
    },
    goToServiceDetail(serviceId) {
      this.$store.dispatch("services/selectService", serviceId);
      this.$router.push({ name: "service_detail", params: { id: serviceId } });
      localStorage.setItem("selectedServiceId", serviceId);
    },

    async fetchServices() {
      try {
        const res = await axios.get(`${this.apiUrl}/api-services`);
        if (res && res.status === 200) {
          this.fetchedServices = res.data.data.map((service) => ({
            ...service,
            isRotated: false,
          }));
        }
      } catch (err) {
        console.error(err);
      }
    },
  },
};
</script>

<style scoped>
.section-divider {
  width: 330px !important;
  /* Full width of the container */
  height: 5px;
  margin: 0 auto;
  /* Height of the divider, adjust as needed */
  background-image: url("https://magency.s3.eu-central-1.amazonaws.com/images/banner.jpg");
  /* The path to your banner image */
  background-size: cover;
  /* Cover ensures that the banner covers the div area */
  background-repeat: no-repeat;
  background-position: center;
  /* Center the image within the div */
}

.read_more_btn {
  position: relative !important;
  bottom: -20px !important;
  /* Adjust as needed to position the button from the bottom */
  /* left: 10px !important; */
}

.features-area {
  background: transparent !important;
}

.single-card {
  height: 265px;
  width: 300px;

  /* margin: 0 auto; */
  /* position: relative; */
}

.wrapper-con {
  perspective: 900px;
}

.single-card {
  text-align: center;
  transition: all 0.9s cubic-bezier(0.7, -0.5, 0.3, 1.8);
  transform-style: preserve-3d;
}

.rotate-card {
  transform: rotateY(180deg);
  /* Rotate the card 180 degrees on the Y-axis */
}

.front,
.back {
  width: 300px;
  position: relative;
  top: 0;
  left: 0;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}

.front {
  cursor: pointer;
  height: 100%;
  background: transparent;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  border-radius: 15px;
  background-size: cover;
  background-position: center center;
}

.front:hover {
  background: #4a2c8221 !important;
}

.front .card-title {
  color: #ffffff;
  font-size: 17px !important;
  margin-top: 10px !important;
}

.back {
  transform: rotateY(180deg);
  position: absolute;
  border-radius: 15px;
  height: 100%;
  background: #4a2c8221;
  color: #ffffff !important;
}

.content .description {
  color: #ffffff;
  font-size: 20px !important;
}

.socials {
  margin-top: 20%;
}

.socials i {
  font-size: 30px;
  margin: 0 10px;
}

/* ===== Buttons Css ===== */
.features-style-one .primary-btn-outline {
  border-color: #4a2c82;
  color: #4a2c82;
}

.features-style-one .active.primary-btn-outline,
.features-style-one .primary-btn-outline:hover,
.features-style-one .primary-btn-outline:focus {
  background: var(--primary-dark);
  color: var(--white);
}

.features-style-one .deactive.primary-btn-outline {
  color: var(--dark-3);
  border-color: var(--gray-4);
  pointer-events: none;
}

/*===========================
  Features One CSS
===========================*/
.features-one {
  background-color: #ffffff;
  padding-top: 120px;
  padding-bottom: 120px;
}

.features-one .section-title {
  padding-bottom: 10px;
}

.features-one .title {
  font-size: 44px;
  font-weight: 600;
  color: #ffffff;
  line-height: 55px;
}

@media (max-width: 767px) {
  .features-one .title {
    font-size: 30px;
    line-height: 35px;
  }
}

.features-one .text {
  font-size: 16px;
  line-height: 24px;
  color: rgb(255, 255, 255);
  margin-top: 24px;
}

.features-style-one {
  background-color: white !important;
  padding: 40px 20px;
  margin-top: 40px;

  border-radius: 4px;
  transition: all 0.3s;
}

.features-style-one .features-icon {
  position: relative;
  display: inline-block;
  z-index: 1;
  height: 160px;
  width: 160px;
  line-height: 100px;
  text-align: center;
  font-size: 40px;
  color: #4a2c82;
  /* border: 2px solid rgba(187, 187, 187, 0.192); */
  border-radius: 50%;
  -webkit-transition: all 0.3s ease-out 0s;
  -moz-transition: all 0.3s ease-out 0s;
  -ms-transition: all 0.3s ease-out 0s;
  -o-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
}

@media (max-width: 767px) {
  .features-style-one .features-icon {
    height: 70px;
    width: 70px;
    line-height: 70px;
    font-size: 35px;
  }
}

.features-style-one .features-content {
  margin-top: 24px;
}

.features-style-one .features-title {
  font-size: 23px;
  line-height: 35px;
  font-weight: 600;
  color: var(--black);
  -webkit-transition: all 0.3s ease-out 0s;
  -moz-transition: all 0.3s ease-out 0s;
  -ms-transition: all 0.3s ease-out 0s;
  -o-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
}

@media only screen and (min-width: 992px) and (max-width: 1199px),
  (max-width: 767px) {
  .features-style-one .features-title {
    font-size: 22px;
  }
}

.features-style-one .text {
  color: var(--dark-3);
  margin-top: 16px;
}

.features-style-one .features-btn {
  margin-top: 32px;
}

@media only screen and (max-width: 767px) {
  .features-one {
    padding-bottom: 0px !important;
  }
}
</style>
